class Sticky {
    constructor(c = {}) {
        this.elem = c.elem
        this._parent = c.parent
        this.elem_h = this.elem.outerHeight(true)
        this.parent_h = this._parent.outerHeight(true)
        this.max_scroll = parseFloat(this.parent_h) - parseFloat(this.elem_h)

        this.start()
    }
    start() {
        this.listener()
    }
    listener() {
        $(window).on('scroll', e => {
            this.sticky()
        })
        $(window).on('resize', e => {
            this.elem_h = this.elem.outerHeight(true)
            this.parent_h = this._parent.outerHeight(true)
            this.max_scroll = parseFloat(this.parent_h) - parseFloat(this.elem_h)
        })
    }
    sticky() {
        if (this._parent) {
            let offset_t = this._parent.offset().top
            let window_scroll_t = $(window).scrollTop()
            if (
                parseFloat(window_scroll_t) >=
                parseFloat(offset_t)
            ) {

                if (
                    (parseFloat(window_scroll_t) - parseFloat(offset_t)) + 75 <=
                    parseFloat(this.max_scroll)
                ) {
                    let calculated = parseFloat(window_scroll_t) - parseFloat(offset_t)
                    this.elem.css({
                        transform: `translate3d(0, ${calculated}px, 0)`
                    })
                }
            } else if ((parseFloat(window_scroll_t) <= parseFloat(offset_t))) {
                this.elem.css({
                    transform: `translate3d(0, 0px, 0)`
                })
            }
            if ($('.b-header').css('position') == 'fixed' && (parseInt(offset_t) - parseInt($('.b-header').outerHeight(true))) <= parseInt(window_scroll_t)) {
                this.elem.css({
                    maxHeight: `calc(100vh - ${$('.b-header').outerHeight(true)}px)`,
                    paddingTop: `${$('.b-header').outerHeight(true)}px`
                })
                this.elem.css({
                    maxHeight: `calc(100vh - ${$('.b-header').outerHeight(true)}px)`,
                    paddingTop: `${$('.b-header').outerHeight(true)}px`
                })
            } else {
                this.elem.css({
                    maxHeight: ``,
                    paddingTop: ``
                })
                this.elem.css({
                    maxHeight: ``,
                    paddingTop: ``
                })
            }
        }
        // requestAnimationFrame(this.sticky)
    }
}
// if ($('.b-sidebar__inner').length > 0) {
//     for (let i = 0; i < $('.b-sidebar__inner').length; i++) {
//         new Sticky({
//             elem: $('.b-sidebar__inner').eq(i),
//             parent: $('.b-sidebar__inner').eq(i).parent()
//         })
//     }
// }