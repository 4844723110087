$(window).on('scroll', e => {
    //if ($(window).innerWidth() > 560) {
        if ($(window).scrollTop() > 20) {
            $(".b-page").css({
                paddingTop: `${$(".b-header").outerHeight(true)}px`
            })
            $(".b-header").addClass('active')
        } else {
            $(".b-page").css({
                paddingTop: `0px`
            })
            $(".b-header").removeClass('active')
        }
    //}
})