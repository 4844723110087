class Submenu {
    constructor() {
        // this.triggers = $('.b-menu > ul > li > a')
        this.triggers = $('li:not(.js-allow-click) [data-submenu-trigger]')
        this.triggers_close = $('[data-submenu-trigger-close]')
        this.contents = $('[data-submenu-content]')

        this.listener()
    }
    listener() {
        /*this.triggers.on('click', e => {
            this.handler_click(e)
        })
        this.triggers_close.on('click', e => {
            this.handler_click_close(e)
        })*/

        $(window).on('scroll', e => {
            this.contents.each((index, item) => {
                this.remove($(item))
            })
        })
    }
    handler_click(e) {
        e.preventDefault()
        this.contents.each((index, item) => {
            this.remove($(item))
            if (
                $(e.currentTarget).data('submenuTrigger') ===
                $(item).data('submenuContent')
            ) {
                this.toggle($(item))
            }
        })
    }
    handler_click_close(e) {
        e.preventDefault()
        this.contents.each((index, item) => {
            this.remove($(item))
        })
    }
    toggle(current_content /* jQuery.object */ ) {
        current_content.toggleClass('active')
    }
    remove(current_content /* jQuery.object */ ) {
        current_content.removeClass('active')
    }
    add(current_content /* jQuery.object */ ) {
        current_content.addClass('active')
    }
}

new Submenu();