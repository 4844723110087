$(".b-sidebar__inner").css({
    top: `${$('.b-header').outerHeight(true)}px`
})
$(".b-calculator__price-inner").css({
    top: `${$('.b-header').outerHeight(true)}px`
})
$(".b-submenu").css({
    top: `${$('.b-header').outerHeight(true)}px`
})
// $(".b-sidebar__content").css({
//     maxHeight: `${parseInt($(window).innerHeight()) - parseInt($('.b-header').outerHeight(true)) - 30}px`
// })
$(".b-mobile-menu").css({
    paddingTop: `${$('.b-header').outerHeight(true)}px`
})

$(window).on('resize', e => {
    $(".b-sidebar__inner").css({
        top: `${$('.b-header').outerHeight(true)}px`
    })
    $(".b-calculator__price-inner").css({
        top: `${$('.b-header').outerHeight(true)}px`
    })
    $(".b-submenu").css({
        top: `${$('.b-header').outerHeight(true)}px`
    })
    // $(".b-sidebar__content").css({
    //     maxHeight: `${parseInt($(window).innerHeight()) - parseInt($('.b-header').outerHeight(true)) - 30}px`
    // })
    $(".b-mobile-menu").css({
        paddingTop: `${$('.b-header').outerHeight(true)}px`
    })
})


let sidebar_flag = false
$('[data-sidebar-trigger]').on('click', e => {
    if (!sidebar_flag) {
        $('.b-section__sidebar').addClass('active')
        $('body').css({
            overflow: 'hidden'
        })
        sidebar_flag = !sidebar_flag
    } else {
        $('.b-section__sidebar').removeClass('active')
        $('body').css({
            overflow: ''
        })
        sidebar_flag = !sidebar_flag
    }
})


$(window).on('scroll resize', e => {
    // if ($('.b-header').css('position') == 'fixed') {
    //     $('.b-sidebar__inner').css({
    //         maxHeight: `calc(100vh - ${$('.b-header').outerHeight(true)}px)`,
    //         paddingTop: `${$('.b-header').outerHeight(true)}px`
    //     })
    //     $('.b-sidebar__inner').css({
    //         maxHeight: `calc(100vh - ${$('.b-header').outerHeight(true)}px)`,
    //         paddingTop: `${$('.b-header').outerHeight(true)}px`
    //     })
    // } else {
    //     $('.b-sidebar__inner').css({
    //         maxHeight: ``,
    //         paddingTop: ``
    //     })
    //     $('.b-sidebar__inner').css({
    //         maxHeight: ``,
    //         paddingTop: ``
    //     })
    // }
    if ($(window).scrollTop() > $(window).outerHeight()) {
        $('.b-go-to-start').addClass('active')
    } else {
        $('.b-go-to-start').removeClass('active')
    }
})