let mob_menu_flag = false
$('.b-mobile-menu-trigger').on('click', e => {
    $(e.currentTarget).toggleClass('active')
    if (!mob_menu_flag) {
        $('.b-mobile-menu').addClass('active')
        $('body').css({
            overflow: 'hidden'
        })
        $('.b-page').css({
            overflow: 'hidden'
        })
    } else {
        $('.b-mobile-menu').removeClass('active')
        $('body').css({
            overflow: ''
        })
        $('.b-page').css({
            overflow: ''
        })
    }
    mob_menu_flag = !mob_menu_flag
})