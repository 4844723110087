class Ancore_link {
    constructor() {
        this.links = $('a:not(.js-category-filter)')

        this.listener()
    }
    listener() {
        this.links.on('click', e => {
            let content_id = $(e.currentTarget).attr('href')
            if (content_id && content_id[0] === '#') {
                e.preventDefault()
                if ($(content_id).length > 0) {
                    $('html,body').animate({
                        scrollTop: parseInt($(content_id).offset().top) - parseInt($('.b-header').outerHeight(true))
                    }, 1000);
                }
            }
        })
    }
}

new Ancore_link()