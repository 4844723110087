document.addEventListener('DOMContentLoaded', e => {
    const trigger = document.querySelector('[data-fast-order-trigger]')
    const anchor = document.querySelector('[data-fast-order-anchor]')
    if(!trigger) return
    if(!anchor) return
    let isFastOrderAScrolled = false
    let savePageOffsetY = 0

    const scrollTo = top => {
        window.scroll({
            behavior: 'smooth',
            left: 0,
            top: top
        });
    }

    const getBodyScrollTop = () => self.pageYOffset || (document.documentElement && document.documentElement.scrollTop) || (document.body && document.body.scrollTop)

    const toggleFastOrderTriggerClass = () => {
        trigger.classList.toggle('b-order-fast-cart-trigger_1')
        trigger.classList.toggle('b-order-fast-cart-trigger_2')
    }

    trigger.addEventListener('click', e => {
        toggleFastOrderTriggerClass()
        if (!isFastOrderAScrolled) {
            savePageOffsetY = getBodyScrollTop()
            scrollTo(anchor.offsetTop);
            isFastOrderAScrolled = true
        } else {
            scrollTo(savePageOffsetY);
            isFastOrderAScrolled = false
        }
    })
})