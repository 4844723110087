$('.b-button').on('click', e => {
    if ($(e.currentTarget).hasClass('b-button_disabled')) {
        e.preventDefault()
    }
})
/*
$('form.b-sidebar__inner').on('input', e => {
    e.preventDefault()

    var data = [];
    $(e.currentTarget).find('input[type="checkbox"]').each((index, item) => {
        data.push($(item).is(':checked'))
    });
    if (data.includes(true)) {
        $('form.b-sidebar__inner .b-button').removeClass('b-button_disabled')
        $('form.b-sidebar__inner .b-button').addClass('b-button_grad')
        $('form.b-sidebar__inner .b-button').addClass('b-button_color-light')
            // 
        $('form.b-sidebar__inner .b-button').addClass('b-button_anim')

        // let tineout = setTimeout(e => {
        //     $('form.b-sidebar__inner .b-button').removeClass('b-button_anim')
        // }, 600)
    } else {
        $('form.b-sidebar__inner .b-button').addClass('b-button_disabled')
        $('form.b-sidebar__inner .b-button').removeClass('b-button_grad')
        $('form.b-sidebar__inner .b-button').removeClass('b-button_color-light')
        $('form.b-sidebar__inner .b-button').removeClass('b-button_anim')
    }
})
$(document).ready(e => {
    var data = [];
    $('form.b-sidebar__inner').find('input[type="checkbox"]').each((index, item) => {
        data.push($(item).is(':checked'))
    });
    if (data.includes(true)) {
        $('form.b-sidebar__inner .b-button').removeClass('b-button_disabled')
        $('form.b-sidebar__inner .b-button').addClass('b-button_grad')
        $('form.b-sidebar__inner .b-button').addClass('b-button_color-light')
        $('form.b-sidebar__inner .b-button').addClass('b-button_anim')
    } else {
        $('form.b-sidebar__inner .b-button').addClass('b-button_disabled')
        $('form.b-sidebar__inner .b-button').removeClass('b-button_grad')
        $('form.b-sidebar__inner .b-button').removeClass('b-button_color-light')
        $('form.b-sidebar__inner .b-button').removeClass('b-button_anim')
    }
})*/
