class Range {
    constructor(c = {}) {
        this.input = c.input
        this.output = c.output
        this.text = c.text
        this.trigger_minus = c.minus
        this.trigger_plus = c.plus
        this.min = c.input.attr('min')
        this.max = c.input.attr('max')
            // 
        this.start()
    }
    start() {
        this.listener()
        this._output()
        this.disabled()
    }
    _val() {
        return this.input.val()
    }
    listener() {
        this.trigger_minus.on('click', () => {
            this.minus()
            this._output()
            this.disabled()
            this.disp_event()
        })
        this.trigger_plus.on('click', () => {
            this.plus()
            this._output()
            this.disabled()
            this.disp_event()
        })
    }
    minus() {
        if (parseInt(this._val()) === parseInt(this.min)) return
        this.input.val(parseInt(this._val()) - 1)
    }
    plus() {
        if (parseInt(this._val()) === parseInt(this.max)) return
        this.input.val(parseInt(this._val()) + 1)
    }
    disabled() {
        if (parseInt(this._val()) === parseInt(this.min)) {
            this.trigger_minus.addClass('disabled')
        } else if (parseInt(this._val()) === parseInt(this.max)) {
            this.trigger_plus.addClass('disabled')
        } else {
            this.trigger_minus.removeClass('disabled')
            this.trigger_plus.removeClass('disabled')
        }
    }
    _output() {
        this.output.text(`${this._val()} ${this.text}`)
    }
    disp_event() {
        this.input.trigger('range_change')
    }
}

jQuery(document).ready(() => {
    for (let i = 0; i < $('[data-input-range]').length; i++) {
        new Range({
            input: $('[data-input-range]').eq(i).children('.b-input-range__input'),
            output: $('[data-input-range]').eq(i).children('.b-input-range__output'),
            minus: $('[data-input-range]').eq(i).children('.b-input-range__trigger_minus'),
            plus: $('[data-input-range]').eq(i).children('.b-input-range__trigger_plus'),
            text: $('[data-input-range]').eq(i).data('inputRange')
        })
    }
})