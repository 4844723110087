class Modal {
    constructor(c) {
        this.modalName = c.name;
        this.modalBox  = $('[data-modal-window="' + this.modalName + '"]');
        this.modal     = this.modalBox.children('.b-modal');
        this.trigger   = $('[data-modal-trigger="' + this.modalName + '"]');
        this.flag      = false;
        this.init();
    }
    init() {
        this.handler()
    }
    handler() {
        this.trigger.on('click', event => {

	        event.preventDefault();


	        const BTN_TITLE = event.target.innerText;
	        const MODAL_WINDOW = document.querySelector(`.b-modal-box[data-modal-window="${this.modalName}"]`);
	        const MODAL_WINDOW_TITLE = (MODAL_WINDOW) && MODAL_WINDOW.querySelector('.b-modal__title');
	        const PRODUCT_NAME_ELEM = document.querySelector('.js-product-name');
	        const PRODUCT_TITLE_INPUT = document.querySelector('.js-product-title');
	        const PRODUCT_TITLE       = event.target.dataset?.product_title;

	        if ( MODAL_WINDOW_TITLE  && BTN_TITLE ) {
		        MODAL_WINDOW_TITLE.innerHTML = BTN_TITLE;
	        }

	        if (this.modalName === 'buy-product') {
		        PRODUCT_NAME_ELEM.innerHTML = (PRODUCT_NAME_ELEM) ? PRODUCT_TITLE : '';
		        PRODUCT_TITLE_INPUT.value = (PRODUCT_TITLE_INPUT) ? PRODUCT_TITLE : ''
	        }


            if (this.flag === false) {
                this.open()
                $('body').css({
                    overflow: 'hidden'
                })
                $('.b-page').css({
                    overflow: 'hidden'
                })
                this.flag = true
            } else if (this.flag === true) {
                this.close()
                $('body').css({
                    overflow: ''
                })
                $('.b-page').css({
                    overflow: ''
                })
                this.flag = false
            }
        })
    }
    open() {
        this.modalBox.addClass('b-modal-box_df')
        setTimeout(e => {
            this.modalBox.addClass('b-modal-box_op1')
            setTimeout(e => {
                this.modal.addClass('b-modal_scale1')
            }, 200)
        }, 50)
    }
    close() {

    	/*const B_MODAL_BOX = document.querySelectorAll('.b-modal-box');

	    (B_MODAL_BOX)
	    && [...B_MODAL_BOX].forEach( popup => {
	    	const B_MODAL = popup.querySelector('.b-modal');
		    (B_MODAL) && B_MODAL.classList.remove('b-modal_scale1');

		    setTimeout(e => {
			    popup.classList.remove('b-modal-box_op1');

			    setTimeout(e => {
				    popup.classList.remove('b-modal-box_df');
			    }, 200);
		    }, 250);
	    });*/

        this.modal.removeClass('b-modal_scale1');
        setTimeout(e => {
            this.modalBox.removeClass('b-modal-box_op1')
            setTimeout(e => {
                this.modalBox.removeClass('b-modal-box_df')
            }, 200)
        }, 250)
    }
}

if ($('[data-modal-trigger]').length > 0) {
    let list_modal_name = []
    for (let i = 0; i < $('[data-modal-trigger]').length; i++) {
        if (!list_modal_name.includes($('[data-modal-trigger]').eq(i).data('modalTrigger'))) {
            new Modal({
                name: $('[data-modal-trigger]').eq(i).data('modalTrigger')
            })
            list_modal_name.push($('[data-modal-trigger]').eq(i).data('modalTrigger'))
        }
    }

}