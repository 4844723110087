class HeaderSearch {
    constructor() {
        this.parent = document.querySelector('.b-header__search')
        this.form = this.parent.querySelector('form')

        this.isActive = false
        this.trigger = this.parent.querySelector('i.pe-7s-search')
        this.triggerListener()
    }
    triggerListener(){
        this.trigger.addEventListener('click', e => {
            this.parent.classList.toggle('active')
            if(this.isActive){
                const form = new FormData(this.form)
                if(form.get('s')){
                    this.form.submit()
                    this.form.reset()
                }
            }
            this.isActive = !this.isActive
        })
    }
}
document.addEventListener('DOMContentLoaded', e => {
    new HeaderSearch()
})