class Content_image {
    constructor() {
        this.images = $('.b-text-block__content img.fullwidth')
        this.radio = 0.4
        this.start()
    }
    start() {
        this.add_height()
        $(window).on('resize', e => {
            this.add_height()
        })
    }
    add_height() {
        this.images.each((index, item) => {
            if (CSS.supports("object-fit", "cover")) {
                $(item).css({
                    height: `${parseInt($(item).width() * this.radio)}px`
                })
            }
        })
    }
}
new Content_image()