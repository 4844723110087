class Calc_filter {
    constructor(c = {}) {
        this.triggers = $('[data-calc-filter-trigger]')
        this.outputs = $('[data-calc-filter-output]')
        this.transition = 222
            // 
        this.start()
    }
    start() {
        this.listener()
    }
    listener() {
        this.triggers.on('click', e => {
            if ($(e.currentTarget).hasClass('active')) {
                return
            }
            this.triggers.removeClass('active')
            $(e.currentTarget).addClass('active')
            let index = $(e.currentTarget).data('calcFilterTrigger')
            let prefix = index.split('-')[0]
            let id = index.split('-')[1]
            console.log(prefix, id)
            if (id === 'all') {
                this.show_all()
            } else {
                this.show_by_id(prefix, id)
            }
        })
    }
    show_all() {
        this.outputs.fadeIn(this.transition)
    }
    show_by_id(prefix, id) {
        this.outputs.fadeOut(this.transition)
        $(`[data-calc-filter-output="${prefix}-${id}"]`).fadeIn(this.transition)
    }
}
new Calc_filter()