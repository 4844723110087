class Hidden_button {
    constructor(c = {}) {
        this.trigger = c.trigger
        this.parent = this.trigger.parent()
        this.index = this.parent.children().index(this.trigger)
        this.transition = 250
            // 

        this.after_items = []

        this.start()
    }
    start() {
        for (let i = 0; i < this.parent.children().length; i++) {
            if (parseInt(this.index) < parseInt(this.parent.children().index(this.parent.children().eq(i)))) {
                this.after_items.push(this.parent.children().eq(i))
                this.hidden_item(this.parent.children().eq(i))
            }
        }

        this.listener()
    }
    hidden_item(item) {
        item.fadeOut(0)
    }
    show_items() {
        for (let i = 0; i < this.after_items.length; i++) {
            this.after_items[i].fadeIn(this.transition + (this.transition * i))
        }
        this.trigger.fadeOut(this.transition)
    }
    listener() {
        this.trigger.on('click', e => {
            this.show_items()
        })
    }
}
for (let i = 0; i < $('[data-hidden-button]').length; i++) {
    new Hidden_button({
        trigger: $('[data-hidden-button]').eq(i)
    })
}