class Calc_price {
    constructor(c = {}) {
        this.inputs = $('[data-input-range-input]')
        this.parent = $('.b-calculator__price-row table')
        this.sum_block = $('.b-calculator__price-row .b-calculator__price-sum')
        this.button = $('.b-calculator__price-row button')
        this.sum = 0
        this.start()
    }
    start() {
        this.listener()
    }
    listener() {
        this.inputs.on('range_change', e => {
            let counter = 0
            this.parent.html('')
            this.sum = 0
            let currency = null
            this.inputs.each((index, item) => {
                let data = {
                    value: parseInt($(item).val()),
                    price: $(item).data('inputRangePrice'),
                    text: $(item).data('inputRangeInput'),
                    unit: $(item).data('inputRangeUnit'),
                    currency: $(item).data('inputRangeCurrency'),
                    index: index
                }
                currency = data.currency
                if (parseInt(data.value) != 0) {
                    this.parent.html(this.parent.html() + this.template(data))
                    this.sum += (parseInt(data.price) * parseInt(data.value))
                    counter = counter + data.value
                }
            })
            console.log(counter)

            // :(
            $('[data-fast-order-counter]').attr('data-fast-order-counter', counter)


            this.sum_block.text(this.sum + ' ' + currency)
            this.listener_drop()

            if (this.parent.html().trim()) {
                this.button.removeClass('b-button_disabled')
                this.button.prop('disabled', false);
            } else {
                this.button.addClass('b-button_disabled')
                this.button.prop('disabled', true);
            }
        })
    }
    template(data) {
        return `<tr>
                    <td>
                        ${data.text}
                    </td>
                    <td>
                        ${data.value} ${data.unit}
                    </td>
                    <td>
                        ${parseInt(data.price) * parseInt(data.value)} ${data.currency}
                    </td>
                    <td>
                        <span class="pe-7s-close" data-range-input-val-null="${data.index}"></span>
                    </td>
                </tr>`
    }
    listener_drop() {
        $('[data-range-input-val-null]').on('click', e => {
            let _index = $(e.currentTarget).data('rangeInputValNull')
            this.inputs.each((index, item) => {
                if (parseInt(index) === parseInt(_index)) {
                    let val = parseInt($(item).val())
                    for (let i = 0; i < val; i++) {
                        $(item).siblings('.b-input-range__trigger_minus').trigger('click')
                    }
                }
            })
        })
    }
}
new Calc_price()