
/**
 * Polyfill for closest method
 */
const closest_polyfill = () => {
	if ( window.Element && !Element.prototype.closest ) {
		Element.prototype.closest =
			function ( s ) {
				let matches = (this.document || this.ownerDocument).querySelectorAll( s ),
					i,
					el = this;
				do {
					i = matches.length;
					while ( --i >= 0 && matches.item( i ) !== el ) {
					}
					;
				} while ( (i < 0) && (el = el.parentElement) );
				return el;
			};
	}
}

// Init Closest polyfill method
closest_polyfill();

/**
 * Activate categories filter on prices page
 *
 */
const activate_categories_filter = () => {
	const PRICES_CATEGORIES_ELEM = document.querySelector(`.js-prices-categories`);
	const CATEGORIES_LIST        = (PRICES_CATEGORIES_ELEM) ? PRICES_CATEGORIES_ELEM.querySelectorAll(`.js-b-button_orange`) : null;

	(CATEGORIES_LIST) && [...CATEGORIES_LIST].forEach((item) => {
		item.classList.remove('active');
	});
}

document.body.addEventListener('change', (event)=>{
	const ROLE = event.target.dataset.role;

	switch (ROLE) {
		case 'filter-form-input' :
			const FORM = event.target.closest('form');
			FORM.dispatchEvent(new Event('submit'));
			break;
		case 'select-categories' :
			{
			event.preventDefault();
			const FILTER_ID              = event.target.value.replace(/^[#]/, '');

			const CATEGORIES_ARR         = document.querySelectorAll('.js-category-items');
			const FILTER_ID_el           = document.querySelector(`#${FILTER_ID}`);

			(CATEGORIES_ARR) && [...CATEGORIES_ARR].forEach((category) => {
				category.classList.remove('active');
			});

			(CATEGORIES_ARR) && [...CATEGORIES_ARR].forEach((category) => {

				if ( category.getAttribute('id') !== FILTER_ID ) return;

					let operationType = (category.classList.contains('active')) ? 'remove' : 'add';

					category.classList[operationType]('active');
					const CATEGORY_NAME_ARR = document.querySelectorAll('.js-category-name');

					(CATEGORY_NAME_ARR) && [...CATEGORY_NAME_ARR].forEach(item => {
						item.classList.remove('active');
					});

					if (operationType === 'add') {
						const CATEGORY_ITEM_WRAPPER_ELEM = category.closest('.js-category-item-wrapper');

						if (CATEGORY_ITEM_WRAPPER_ELEM) {
							const CATEGORY_NAME_ELEM = CATEGORY_ITEM_WRAPPER_ELEM.querySelector('.js-category-name');

							(CATEGORY_NAME_ELEM) && CATEGORY_NAME_ELEM.classList[operationType]('active');
						}
					}

					setTimeout( () => {
						( FILTER_ID_el )
						&& window.scroll({
							top: (FILTER_ID_el.getBoundingClientRect()).top - 80,
							left: 0,
							behavior: 'smooth'
						});
					}, 500 );




			});

		}
			break;
	}
});



document.body.addEventListener('submit', event => {
	const ROLE = event.target.dataset.role;

	switch ( ROLE ) {
		case 'filter_redirect':
			{
				event.preventDefault();

				const FILTER_INPUTS = event.target.querySelectorAll('[data-role="filter-form-input-redirect"]');
				let slugs = [];

				(FILTER_INPUTS)
				&& [...FILTER_INPUTS].forEach( item => {
					if ( !item.checked ) return;
					slugs.push(item.value);
				});

				let new_url = event.target.action + '/products-categories/produkty-bas/?filter=' + slugs.join(',');

				window.location.href = new_url;
			}

			break;

		case 'filter_products' :
			event.preventDefault();

			const URL = document.querySelector('.js-site-bar').dataset?.url;
			if( !URL ) return;

			let filters = event.target.querySelectorAll('input[type="checkbox"]:checked');

			filters = filters && [...filters].map((item) => { return item.value; }).join(',');

			if (filters && filters.length > 0) {
				window.location.href = URL + '?filter=' + filters;
			} else {
				window.location.href = URL;
			}
			break;

		case 'header-search-form':
				{
					const SEARCH_INPUT = event.target.closest('.js-header-search-input');
					SEARCH_INPUT.classList.toggle('active');
					if ( SEARCH_INPUT.classList.contains('active') ) {
						event.preventDefault();
						return;
					}
				}
			break;
	}

});


document.body.addEventListener('click', (event)=>{
	const ROLE = event.target.dataset.role;

	const ELEMENTs = document.querySelectorAll('.js-body-click-close');


	ELEMENTs && [...ELEMENTs].forEach((item)=>{

		if(event.target.closest('.js-body-click-close')) return;
		item.classList.remove('active');

	});

	switch (ROLE) {
		case 'reset-filter' :
			const FORM = event.target.closest('form');
			const CHECK_BOXs = FORM.querySelectorAll('input[type="checkbox"]');
			CHECK_BOXs && [...CHECK_BOXs].forEach((item)=>{
				item.removeAttribute('checked');
			});

			const SUBMIT_BTN = FORM.querySelector('button[type="submit"]');

			SUBMIT_BTN && SUBMIT_BTN.click();



			//document.querySelector('#filter-form button[type="submit"]');

			break;
	}
});


jQuery(document).ready(function(){

	jQuery('.b-sidebar__checkbox-list').mCustomScrollbar();

	let product_in_cart = {};


	/**
	 * Prepare single item in cart layout
	 * @param name
	 * @param qty
	 * @param price
	 * @returns {string}
	 */
	const create_new_product_in_cart = ({id, name, qty, price}) => {

		return `
			 <div class="prices-main__cart-product-item js-product-item">
                    <div class="prices-main__cart-product-name">
                        ${name}
                    </div>
                    <div class="prices-main__additional-product-info">
                        <div class="prices-main__cart-product-quantity js-product-quantity">
                            ${qty} шт.
                        </div>
                        <div class="prices-main__cart-product-price js-product-price">
                            ${+qty * +price} грн.
                        </div>
                    </div>
                    <div class="prices-main__product-remove" data-role="remove-item-from-cart" data-id="${id}">
                        <svg class="prices-main__product-remove-icon" width="10" height="10" 
                        	 viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.488378 10C0.290862 10 0.112778 9.88107 0.037187 9.69859C-0.0384042 9.51611 0.00338814 9.30606 0.143072 9.16641L9.16636 0.143032C9.35706 -0.0476774 9.66626 -0.0476774 9.85697 0.143032C10.0477 0.333742 10.0477 0.642943 9.85697 0.833653L0.833685 9.85703C0.742183 9.94872 0.617915 10.0002 0.488378 10Z" fill="#FF4600"/>
                            <path d="M9.51162 10C9.38208 10.0002 9.25782 9.94872 9.16632 9.85703L0.143031 0.833653C-0.0476769 0.642943 -0.0476769 0.333742 0.143031 0.143032C0.333738 -0.0476774 0.642936 -0.0476774 0.833644 0.143032L9.85693 9.16641C9.99661 9.30606 10.0384 9.51611 9.96281 9.69859C9.88722 9.88107 9.70914 10 9.51162 10Z" fill="#FF4600"/>
                        </svg>
                    </div>
                </div>
		`;
	}


	/**
	 * Send fast order on Prices page
	 */
	const send_fast_order = () => {
		const PRODUCT_ORDER_LIST = document.querySelector('.js-product-order-list');
		const HIDDEN_TEXTAREA    = document.querySelector('.js-fast-order-data');
		let output_html = '';

		for (id in product_in_cart) {
			let product_name = (product_in_cart[id]?.name).trim();
			HIDDEN_TEXTAREA.value += `Название продукта: ${product_name},\nКоличество: ${(product_in_cart[id]?.qty).trim()} шт.,\nЦена: ${product_in_cart[id]?.price * product_in_cart[id]?.qty} грн;\n\n\n`;

			output_html += `
                <div class="prices-main__product-item js-product-item">
					<div class="prices-main__product-item-name prices-main__item-field js-product-name">
						${product_in_cart[id]?.name}
					</div>
					<div class="prices-main__product-item-counter prices-main__item-field js-prices-item-field">
						${product_in_cart[id]?.qty} шт.
					</div>
					<div class="prices-main__product-item-price prices-main__item-field js-product-price">
						${product_in_cart[id]?.price * product_in_cart[id]?.qty} грн
					</div>
				</div>
			`;
		}

		(PRODUCT_ORDER_LIST) ? PRODUCT_ORDER_LIST.innerHTML = output_html : '';
	}


	/**
	 * Update cart with new added products
	 * @param clicked_elem
	 * @param operationType
	 * @param elementIDForDelete
	 */
	const update_cart = (clicked_elem, operationType=null, elementIDForDelete = null) => {
		const PRODUCT           = clicked_elem.closest('.js-product-item');
		const CART_PRODUCTS     = document.querySelector('.js-cart-product-list');
		const CART_ORDER_BTN    = document.querySelector('.js-cart-order-btn');

		if ( !PRODUCT) return;

		if ( !operationType ) {
			const PRODUCT_NAME     = PRODUCT.querySelector('.js-product-name').innerHTML;
			const PRODUCT_QUANTITY = PRODUCT.querySelector('.js-amount-product-in-cart').innerHTML;
			const PRODUCT_PRICE    = PRODUCT.querySelector('.js-product-price').innerHTML;
			let ID                 = PRODUCT.getAttribute('id');

			ID = (ID) ? ID : PRODUCT.dataset?.id;

			if ( !ID ) return;

			product_in_cart = {
				...product_in_cart,
				[ID] : {
					'id': ID,
					'name': PRODUCT_NAME,
					'qty' : PRODUCT_QUANTITY,
					'price': parseInt(PRODUCT_PRICE, 10),
				}
			}

		}

		if ( operationType === 'remove' && elementIDForDelete ) {
			const product = document.querySelector(`.js-amount-product-in-cart[data-id="${elementIDForDelete}"]`);

			if ( product ) {
				product.innerHTML = 0;
			}
			delete product_in_cart[elementIDForDelete];
		}


		CART_ORDER_BTN.style.pointerEvents = (Object.keys(product_in_cart).length === 0 ) ? 'none' : 'painted';

		let product_in_cart_arr = Object.keys(product_in_cart);
		let newResult = '';

		product_in_cart_arr.forEach( item => {
			newResult += create_new_product_in_cart(product_in_cart[item]);
		});

		if ( newResult ) {
			CART_PRODUCTS.innerHTML = newResult;
			return;
		}

		CART_PRODUCTS.innerHTML = '';

	}




	/**
	 * Sets the js and data-role classes to mobile menu items
	 * in the header that have the class .menu-item-has-children
	 *
	 */
	const activate_submenu = () => {
		const MENU_ITEM_HAS_CHILDREN_arr = document.querySelectorAll(`.b-mobile-menu .menu-item-has-children`);

		(MENU_ITEM_HAS_CHILDREN_arr) && [...MENU_ITEM_HAS_CHILDREN_arr].forEach(item => {
			item.classList.add('js-item-has-children');
			item.setAttribute('data-role', 'open-sub-menu');
			const MENU_ITEM_LINK = item.querySelector(`a`);

			if (!MENU_ITEM_LINK) return;
			MENU_ITEM_LINK.classList.add('js-item-has-children');
			MENU_ITEM_LINK.setAttribute('data-role', 'open-sub-menu');
		});
	}

	activate_submenu();


	/**
	 * Debounce function
	 * @param fn     - function that should be executed
	 * @param time   - time delay
	 * @returns {Function}
	 */
	const debounce = ( fn, time = 1000 ) => {
		if ( !fn ) {
			throw Error( '"debounce function - "You didn\'t add required parameters' );
		}

		let timeout;

		return function () {
			const functionCall = () => fn.apply( this, arguments );

			clearTimeout( timeout );
			timeout = setTimeout( functionCall, time );
		}
	};





	/**
	 * Add handler for click event
	 */
	document.body.addEventListener( 'click', event => {
		const ROLE = event.target.dataset.role;

		if ( !ROLE ) return;

		switch ( ROLE ) {

			// Send fast order
			case 'filter_terms':
				event.preventDefault();

				{
					const TERM_ID = event.target.dataset?.term_id;
					const PRODUCT_ITEMs = document.querySelectorAll('.js-product-item');

					(PRODUCT_ITEMs)
					&& [...PRODUCT_ITEMs].forEach( item => {
						const SUBTERM_ID = item.dataset?.term_id;
						const SUBTERM_ID_arr = SUBTERM_ID.split('|');
						let operationType = ( SUBTERM_ID_arr.includes(TERM_ID) ) ? "add" : "remove";

						if ( TERM_ID ==='*' ) {
							operationType = 'remove';
						}

						item.classList[operationType]('not-selected');
					});
				}

				break;

			// Send fast order
			case 'send-fast-order':
				send_fast_order();
				break;

				// Open search input
			case 'open-search-input':
				{
					const HEADER_SEARCH_INPUT = document.querySelector('.js-header-search-input');
					const WRAP_HEADER_SEARCH  = event.target.closest('.js-wrap-search');
					const OPERATION_TYPE =  WRAP_HEADER_SEARCH.classList.contains('active') ? 'remove' : 'add';

					(HEADER_SEARCH_INPUT) && HEADER_SEARCH_INPUT.classList[OPERATION_TYPE]('active');

					(WRAP_HEADER_SEARCH)
					&& WRAP_HEADER_SEARCH.classList[OPERATION_TYPE]('active');
				}

				break;

				// Open sub menu in header mobile menu
			case 'open-sub-menu':
				event.preventDefault();
				const MENU_ITEM_arr = document.querySelectorAll(`b-mobile-menu .menu-item`);
				const OPERATION_TYPE = (event.target.classList.contains('active')) ? 'remove' : 'add';

				(MENU_ITEM_arr) && [...MENU_ITEM_arr].forEach(item => {
					item.classList.remove('active');
				});

				event.target.classList[OPERATION_TYPE]('active');
				break;


			// Open all category items
			case 'open-all-category-items':
				event.preventDefault();
				activate_categories_filter();
				event.target.classList.add("active");

				const CATEGORY_LISTS = document.querySelectorAll('.js-category-items');

				(CATEGORY_LISTS) && CATEGORY_LISTS.forEach((item) => {
					item.classList.add('active');
				});
				break;


			// Opens category-prices-item tabs
			case 'open-category-prices-item':
				const CATEGORY_ITEM_WRAPPER = event.target.closest('.js-category-item-wrapper');
				const CATEGORY_ITEMS_BLOCK  = CATEGORY_ITEM_WRAPPER.querySelector('.js-category-items');
				const CATEGORY_NAME_ELEM    = CATEGORY_ITEM_WRAPPER.querySelector('.js-category-name');
				let operationType           = '';

				if (CATEGORY_ITEMS_BLOCK) {
					operationType = (CATEGORY_ITEMS_BLOCK.classList.contains('active')) ? 'remove' : 'add';

					(CATEGORY_ITEMS_BLOCK) && CATEGORY_ITEMS_BLOCK.classList[operationType]('active');
					(CATEGORY_NAME_ELEM) && CATEGORY_NAME_ELEM.classList[operationType]('active');
				}
				break;

			// Open category list
			case 'open-category-list':
			{
				event.preventDefault();
				const FILTER_ID              = event.target.getAttribute('href').replace(/^[#]/, '');
				const CATEGORIES_ARR         = document.querySelectorAll('.js-category-items');
				const FILTER_ID_el           = document.querySelector(`#${FILTER_ID}`);

				activate_categories_filter();
				event.target.classList.add('active');

				(CATEGORIES_ARR) && [...CATEGORIES_ARR].forEach((category) => {
					category.classList.remove('active');
				});

				(CATEGORIES_ARR) && [...CATEGORIES_ARR].forEach((category) => {
					if ( category.getAttribute('id') !== FILTER_ID ) return;

					let operationType = (category.classList.contains('active')) ? 'remove' : 'add';
					category.classList[operationType]('active');
					const CATEGORY_NAME_ARR = document.querySelectorAll('.js-category-name');

					(CATEGORY_NAME_ARR) && [...CATEGORY_NAME_ARR].forEach(item => {
						item.classList.remove('active');
					});

					if (operationType === 'add') {
						const CATEGORY_ITEM_WRAPPER_ELEM = category.closest('.js-category-item-wrapper');

						if (CATEGORY_ITEM_WRAPPER_ELEM) {
							const CATEGORY_NAME_ELEM = CATEGORY_ITEM_WRAPPER_ELEM.querySelector('.js-category-name');

							(CATEGORY_NAME_ELEM) && CATEGORY_NAME_ELEM.classList[operationType]('active');
						}
					}

					setTimeout( () => {
						( FILTER_ID_el )
						&& window.scroll({
							top: (FILTER_ID_el.getBoundingClientRect()).top - 80,
							left: 0,
							behavior: 'smooth'
						});
					}, 500 );

				});

			}
				break;

			// Selection amount of products
			case 'selection-of-products':
			{
				const PARENT          = event.target.closest('.js-prices-item-field');
				const PRODUCT         = event.target.closest('.js-product-item');
				const OPERATION_TYPE  = event.target.dataset.action;
				const SELECTED_ELEM   = (PARENT) && PARENT.querySelector('.js-amount-product-in-cart');
				const PRODUCT_PRICE   = PRODUCT.querySelector('.js-product-price').innerHTML;
				let existing_value    = (SELECTED_ELEM) && +SELECTED_ELEM.innerHTML;

				if (typeof existing_value === 'undefined') return;

				let temp = (OPERATION_TYPE === 'plus') ? ++existing_value : --existing_value;
				temp     = Math.max(0, temp);

				SELECTED_ELEM.innerHTML =  temp;

				(temp) && update_cart(event.target);

				if ( !temp ) {
					update_cart(event.target, 'remove', PARENT.dataset?.id );
				}

				update_total_price(product_in_cart);
			}
				break;

			// Remove item from cart
			case 'remove-item-from-cart':
			{
				update_cart(event.target, 'remove', event.target.dataset?.id);
				update_total_price(product_in_cart);
			}
				break;

		}
	} );


	document.body.addEventListener('click',(event)=>{
		const TARGET = event.target;
		const FORM = document.querySelectorAll('.js-change-popup-title');
		const TITLE_INFO = document.querySelector('.js-post-title');
		const HEADER_MULTILANG_CLOSEST = event.target.closest('.b-header__multilang');
		const HEADER_MULTILANG_ELEM = document.querySelector('.b-header__multilang.active');

		if ( HEADER_MULTILANG_CLOSEST ) {
			HEADER_MULTILANG_CLOSEST.classList.add('active');
		} else {
			(HEADER_MULTILANG_ELEM) && HEADER_MULTILANG_ELEM.classList.remove('active');
		}



		if ( !TARGET.dataset.modalTrigger ) return;
		if ( !TITLE_INFO ) return;


		[...FORM].forEach( (item) => {
			const POPUP_TITLE = item.querySelector('.js-product-name');

			if ( TITLE_INFO && POPUP_TITLE ) {
				POPUP_TITLE.innerText = TITLE_INFO.dataset.post;
			}
		});

	});


	document.body.addEventListener('wpcf7mailsent', (event) => {
		const FORM = event.target;

		const FORM_WRAPPER = FORM.querySelector('.modal-fast-order-wrapper');
		const MODAL_OVERLAY = document.querySelector('.b-modal__overlay');
		const PRODUCT_LIST = document.querySelector('.js-cart-product-list');
		const PRODUCT_PRICES = document.querySelectorAll('.js-amount-product-in-cart');

		PRODUCT_PRICES && PRODUCT_PRICES.forEach((item) => {
			item.innerText = '0';
		});
		update_total_price(0);
		product_in_cart = {};
		PRODUCT_LIST.innerHTML = '';
		FORM && FORM.classList.add('wpcf7mailsent')

		if(FORM_WRAPPER)  {
			//PRODUCT_LIST.innerHTML = '';
			FORM_WRAPPER.innerHTML = '';
		}

		setTimeout(() => {
			MODAL_OVERLAY && MODAL_OVERLAY.dispatchEvent(new Event('click'));
		}, 1500);
	});


	const INPUT_FILE = document.querySelector('.js-input-file');
	const FILE_TEXT = document.querySelector('.js-file-not-chosen');

	(INPUT_FILE) && INPUT_FILE.addEventListener('change', (event)=>{

		if( INPUT_FILE.size && FILE_TEXT ){
			FILE_TEXT.innerText = INPUT_FILE.value.split(/(\\|\/)/g).pop();
		}
	});
});

const JS_PHONE_BTN = document.querySelector('.js-phone-btn');

(JS_PHONE_BTN) && JS_PHONE_BTN.addEventListener('click' ,(event) => {
	JS_PHONE_BTN.classList.toggle('active');
});


const JS_SELECT_YEAR_OPTIONs = document.querySelectorAll('.js-select-year option');
const JS_SELECT_YEAR = document.querySelector('.js-select-year');
const CURRENT_YEAR = new Date().getFullYear();

if(JS_SELECT_YEAR && JS_SELECT_YEAR.value === '' && false ) {

	JS_SELECT_YEAR_OPTIONs && [...JS_SELECT_YEAR_OPTIONs].forEach((item) => {
		if( item.value.match(CURRENT_YEAR) ) {

			JS_SELECT_YEAR.value = item.value;
			JS_SELECT_YEAR.dispatchEvent(new Event('change'));
		}
	});
}


jQuery(document).ready(function() {
	const contain_class = jQuery('body');
	const container_list = jQuery('.b-header__multilang');
	if (contain_class.hasClass('post-type-archive-news')) {
		let link_list = container_list.find('a');
		link_list.each( (index, val) => {

			let re = /(20[0-9]{2})/;
			let str = val.href;

			if(str) {
				let new_match = str.match(re);
				let new_str = (new_match) ?  'news/' + new_match[0] : null;
				val.href = str.replace(re, new_str);
				console.log(new_str)
			}
		});
	}

});

/**
 * Update total price of cart
 * @param cartProducts
 */
function update_total_price (cartProducts)  {
	const TOTAL_PRICE_ELEM  = document.querySelector('.js-cart-total-price');

	let product_in_cart_arr = Object.keys(cartProducts);
	let newResult = product_in_cart_arr.reduce( (accum, current) => {
		return accum + ( +cartProducts[current].qty * +cartProducts[current].price );
	}, 0 );

	if (TOTAL_PRICE_ELEM) {
		TOTAL_PRICE_ELEM.innerHTML = newResult + ' грн.';
	}
}